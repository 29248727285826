import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { OrganizationApiPort } from '../../application/organization/port/organization-api.port';
import { UserSessionPort } from '../../application/user/port/user-session.port';
import { ApiAdapter } from '../common/api.adpater';
import { OrganizationOutput } from '../graphql/iam/organization/model/organization.output';
import { FetchOrganizationByIdApiQuery } from '../graphql/iam/organization/query/fetch-organization-by-id.query';
import { FetchOrganizationByInvitationCodeApiQuery } from '../graphql/iam/organization/query/fetch-organization-by-invitation-code.query';

@Injectable()
export class OrganizationApiAdapter
  extends ApiAdapter
  implements OrganizationApiPort
{
  constructor(
    userSessionPort: UserSessionPort,
    translateService: TranslateService,
    private fetchOrganizationByIdApiQuery: FetchOrganizationByIdApiQuery,
    private fetchOrganizationByInvitationCodeApiQuery: FetchOrganizationByInvitationCodeApiQuery
  ) {
    super(userSessionPort, translateService);
  }

  public fetchOrganizationByInvitationCode(
    code: string
  ): Observable<OrganizationOutput> {
    return this.fetch$(this.fetchOrganizationByInvitationCodeApiQuery, {
      code,
    });
  }

  public fetchOrganizationById(id: string): Observable<OrganizationOutput> {
    return this.fetch$(this.fetchOrganizationByIdApiQuery, {
      id,
    });
  }
}
