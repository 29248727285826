import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { ComponentController, filterNil } from '@lycoris-nubila/ngx-lycoris';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AppState, AppStore } from './app.store';

@Injectable({ providedIn: 'root' })
export class AppController extends ComponentController<AppState> {
  public loading$: Observable<boolean>;
  public redirecting$: Observable<boolean>;

  constructor(
    store: AppStore,
    private _routerQuery: RouterQuery,
    private _translateService: TranslateService
  ) {
    super(store);

    this._initObservables();
    this._initListeners();
  }

  private _initListeners() {
    this._routerQuery
      .selectQueryParams<string>('lang')
      .pipe(
        filterNil(),
        tap((lang) => this._translateService.use(lang))
      )
      .subscribe();
  }

  private _initObservables() {
    this.redirecting$ = this.select('redirecting').pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.loading$ = this.select('loading').pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }
}
