import { Injectable } from '@angular/core';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  switchMap,
  switchMapTo,
  tap,
} from 'rxjs/operators';
import { AppMessagingService } from '../common/app-messaging.service';
import { ChangeUserPasswordCmd } from './command/change-user-password.cmd';
import { CreateUserPasswordCmd } from './command/create-user-password.cmd';
import { RequestInvitationCmd } from './command/request-invitation.cmd';
import { UserApiPort } from './port/user-api.port';
import { UserApplicationPort } from './port/user-application.port';
import { UserSessionPort } from './port/user-session.port';
import { GenerateUserJwtWithCredentialsQuery } from './query/generate-user-jwt-with-credentials.query';
import { UserOutput } from '../../infrastructure/graphql/iam/user/model/user.output';
import { GenerateUserJwtWithMfaQuery } from './query/generate-user-jwt-with-mfa.query';
import { InviteCoachCmd } from './command/invite-coach.cmd';

@Injectable()
export class UserApplicationAdapter implements UserApplicationPort {
  constructor(
    private userApiPort: UserApiPort,
    private userSessionPort: UserSessionPort,
    private appMessagingService: AppMessagingService
  ) {}

  public requestInvitation(cmd: RequestInvitationCmd): Observable<boolean> {
    return this.userApiPort.requestInvitation(cmd).pipe(
      catchError((e: Error) => {
        this.appMessagingService.publishMessage({
          summaryKey: 'error.user.sign-up.summary',
          detailKey: e.message,
          severity: 'error',
        });
        return throwError(e);
      })
    );
  }

  public markUserTermsOfUseAccepted(): Observable<void> {
    return this.userApiPort.markUserTermsOfUseAccepted();
  }

  public changeUserPassword(cmd: ChangeUserPasswordCmd): Promise<void> {
    return from(this.userApiPort.changeUserPassword(cmd))
      .pipe(
        tap((jwt) => this.userSessionPort.saveUserJwt(jwt)),
        switchMapTo(EMPTY)
      )
      .toPromise();
  }

  public clearSession(): void {
    this.userSessionPort.removeUserJwt();
  }

  public createUserPassword(cmd: CreateUserPasswordCmd): Promise<void> {
    return from(this.userApiPort.createUserPassword(cmd))
      .pipe(
        tap((jwt) => this.userSessionPort.saveUserJwt(jwt)),
        switchMapTo(EMPTY)
      )
      .toPromise();
  }

  public fetchUserByActivationCode(
    activationCode: string
  ): Promise<UserOutput> {
    return this.userApiPort.fetchUserByActivationCode(activationCode);
  }

  public fetchUserByChangePasswordCode(
    changePasswordCode: string
  ): Promise<UserOutput> {
    return this.userApiPort.fetchUserByChangePasswordCode(changePasswordCode);
  }

  public updateUserGrantNewsLetter(grantNewsLetter: boolean): Observable<void> {
    return this.userApiPort.updateUserGrantNewsLetter(grantNewsLetter);
  }

  public generateUserJwtWithMfa(
    query: GenerateUserJwtWithMfaQuery
  ): Observable<void> {
    return this.userApiPort.generateUserJwtWithMfa(query).pipe(
      tap((jwt) => this.userSessionPort.saveUserJwt(jwt)),
      catchError((e: Error) => {
        this.appMessagingService.publishMessage({
          severity: 'error',
          summaryKey: 'error.user.authentication.summary',
          detailKey: e.message,
        });
        return throwError(e);
      }),
      mapTo(void 0)
    );
  }

  public generateUserJwtWithCredentials(
    query: GenerateUserJwtWithCredentialsQuery
  ): Promise<{ jwt: string; isAlt: boolean }> {
    return from(this.userApiPort.generateUserJwtWithCredentials(query))
      .pipe(
        map((jwt) => ({
          jwt,
          isAlt: JSON.parse(atob(jwt.split('.')[1])).altJwt,
        })),
        tap(
          (data) => !data.isAlt && this.userSessionPort.saveUserJwt(data.jwt)
        ),
        catchError((e: Error) => {
          this.appMessagingService.publishMessage({
            severity: 'error',
            summaryKey: 'error.user.authentication.summary',
            detailKey: e.message,
          });
          return of(null);
        })
      )
      .toPromise();
  }

  public generateUserJwtWithNetwork(auth0UserId: string): Promise<string> {
    return from(this.userApiPort.generateUserJwtWithNetwork(auth0UserId))
      .pipe(
        tap((jwt) => this.userSessionPort.saveUserJwt(jwt)),
        catchError((e: Error) => {
          this.appMessagingService.publishMessage({
            severity: 'error',
            summaryKey: 'error.user.authentication.summary',
            detailKey: e.message,
          });
          throw e;
        }),
        switchMapTo(EMPTY)
      )
      .toPromise();
  }

  public observeConnectedUser(): Observable<UserOutput> {
    return this.userSessionPort
      .observeUserJwt()
      .pipe(
        switchMap((jwt) =>
          jwt
            ? from(this.userApiPort.fetchAuthenticatedUser()).pipe(
                catchError(() => of(null as UserOutput))
              )
            : of(null as UserOutput)
        )
      );
  }

  public requestResetPassword(emailAddress: string): Promise<void> {
    return from(this.userApiPort.requestResetPassword(emailAddress))
      .pipe(
        catchError((e: Error) => {
          this.appMessagingService.publishMessage({
            severity: 'error',
            summaryKey: 'error.user.authentication.summary',
            detailKey: e.message,
          });
          return throwError(e);
        }),
        mapTo(void 0)
      )
      .toPromise();
  }

  public inviteCoach(cmd: InviteCoachCmd): Observable<void> {
    return this.userApiPort.inviteCoach(cmd).pipe(
      catchError((e: Error) => {
        this.appMessagingService.publishMessage({
          summaryKey: 'error.coach.sign-up.summary',
          detailKey: e.message,
          severity: 'error',
        });
        return throwError(e);
      })
    );
  }
}
