import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ChangeUserPasswordCmd } from '../../application/user/command/change-user-password.cmd';
import { CreateUserPasswordCmd } from '../../application/user/command/create-user-password.cmd';
import { UserApiPort } from '../../application/user/port/user-api.port';
import { UserSessionPort } from '../../application/user/port/user-session.port';
import { GenerateUserJwtWithCredentialsQuery } from '../../application/user/query/generate-user-jwt-with-credentials.query';
import { ApiAdapter } from '../common/api.adpater';
import { UserOutput } from '../graphql/iam/user/model/user.output';
import {
  ChangeUserPasswordApiMutation,
  ChangeUserPasswordParams,
} from '../graphql/iam/user/mutation/change-user-password.mutations';
import {
  CreateUserPasswordApiMutation,
  CreateUserPasswordParams,
} from '../graphql/iam/user/mutation/create-user-password.mutation';
import { MarkUserTermsOfUseAcceptedApiMutation } from '../graphql/iam/user/mutation/mark-user-terms-of-use-accepted.mutations';
import {
  RequestResetPasswordApiMutation,
  RequestResetPasswordParams,
} from '../graphql/iam/user/mutation/request-reset-password.mutations';
import { FetchAuthenticatedUserApiQuery } from '../graphql/iam/user/query/fetch-authenticated-user.query';
import {
  FetchUserByActivationCodeApiQuery,
  FetchUserByActivationCodeParams,
} from '../graphql/iam/user/query/fetch-user-by-activation-code.query';
import {
  FetchUserByResetCodeParams,
  FetchUserByRestCodeApiQuery,
} from '../graphql/iam/user/query/fetch-user-by-reset-code.query';
import {
  GenerateUserJwtWithCredentialsApiQuery,
  GenerateUserJwtWithCredentialsParams,
} from '../graphql/iam/user/query/generate-user-jwt-with-credentials.query';
import {
  GenerateUserJwtWithNetworkApiQuery,
  GenerateUserJwtWithNetworkParams,
} from '../graphql/iam/user/query/generate-user-jwt-with-network.query';
import { RequestInvitationApiMutation } from '../graphql/iam/user/mutation/request-invitation.mutations';
import { GenerateUserJwtWithMfaApiQuery } from '../graphql/iam/user/query/generate-user-jwt-with-mfa.query';
import { UpdateUserGrantNewsLetterApiMutation } from '../graphql/messaging/user/mutation/update-user-grant-newsletter.api-mutation';
import { RequestInvitationCmd } from '../../application/user/command/request-invitation.cmd';
import { GenerateUserJwtWithMfaQuery } from '../../application/user/query/generate-user-jwt-with-mfa.query';
import { InviteCoachApiMutation } from '../graphql/iam/user/mutation/invite-coach.mutations';
import { InviteCoachCmd } from '../../application/user/command/invite-coach.cmd';

@Injectable()
export class UserApiAdapter extends ApiAdapter implements UserApiPort {
  constructor(
    userSessionPort: UserSessionPort,
    translateService: TranslateService,
    private fetchUserByRestCodeApiQuery: FetchUserByRestCodeApiQuery,
    private _requestInvitationApiMutation: RequestInvitationApiMutation,
    private createUserPasswordApiMutation: CreateUserPasswordApiMutation,
    private changeUserPasswordApiMutation: ChangeUserPasswordApiMutation,
    private fetchAuthenticatedUserApiQuery: FetchAuthenticatedUserApiQuery,
    private _generateUserJwtWithMfaApiQuery: GenerateUserJwtWithMfaApiQuery,
    private requestResetPasswordApiMutation: RequestResetPasswordApiMutation,
    private fetchUserByActivationCodeApiQuery: FetchUserByActivationCodeApiQuery,
    private _generateUserJwtWithNetworkApiQuery: GenerateUserJwtWithNetworkApiQuery,
    private _updateUserGrantNewsLetterApiMutation: UpdateUserGrantNewsLetterApiMutation,
    private _markUserTermsOfUseAcceptedApiMutation: MarkUserTermsOfUseAcceptedApiMutation,
    private _generateUserJwtWithCredentialsApiQuery: GenerateUserJwtWithCredentialsApiQuery,
    private _inviteCoachApiMutation: InviteCoachApiMutation
  ) {
    super(userSessionPort, translateService);
  }

  public requestInvitation(cmd: RequestInvitationCmd): Observable<boolean> {
    return this.mutate$(this._requestInvitationApiMutation, {
      input: cmd,
    });
  }

  public updateUserGrantNewsLetter(grantNewsLetter: boolean): Observable<void> {
    return this.mutate$(this._updateUserGrantNewsLetterApiMutation, {
      grantNewsLetter: !!grantNewsLetter,
    });
  }

  public createUserPassword(cmd: CreateUserPasswordCmd): Promise<string> {
    return this.mutate<string, CreateUserPasswordParams>(
      this.createUserPasswordApiMutation,
      {
        input: {
          activationCode: cmd.activationCode,
          auth0UserId: cmd.auth0UserId,
          password: cmd.password,
        },
      }
    );
  }

  public changeUserPassword(cmd: ChangeUserPasswordCmd): Promise<string> {
    return this.mutate<string, ChangeUserPasswordParams>(
      this.changeUserPasswordApiMutation,
      { input: { resetCode: cmd.resetCode, password: cmd.password } }
    );
  }

  public requestResetPassword(emailAddress: string): Promise<boolean> {
    return this.mutate<boolean, RequestResetPasswordParams>(
      this.requestResetPasswordApiMutation,
      { emailAddress: emailAddress }
    );
  }

  public generateUserJwtWithMfa(
    query: GenerateUserJwtWithMfaQuery
  ): Observable<string> {
    return this.fetch$(this._generateUserJwtWithMfaApiQuery, query);
  }

  public generateUserJwtWithCredentials(
    query: GenerateUserJwtWithCredentialsQuery
  ): Promise<string> {
    return this.fetch<string, GenerateUserJwtWithCredentialsParams>(
      this._generateUserJwtWithCredentialsApiQuery,
      {
        input: {
          emailAddress: query.emailAddress,
          rawPassword: query.password,
        },
      }
    );
  }

  public generateUserJwtWithNetwork(auth0UserId: string): Promise<string> {
    return this.fetch<string, GenerateUserJwtWithNetworkParams>(
      this._generateUserJwtWithNetworkApiQuery,
      { auth0UserId }
    );
  }

  public fetchUserByActivationCode(
    activationCode: string
  ): Promise<UserOutput> {
    return this.fetch<UserOutput, FetchUserByActivationCodeParams>(
      this.fetchUserByActivationCodeApiQuery,
      { activationCode }
    );
  }

  public fetchUserByChangePasswordCode(resetCode: string): Promise<UserOutput> {
    return this.fetch<UserOutput, FetchUserByResetCodeParams>(
      this.fetchUserByRestCodeApiQuery,
      { resetCode }
    );
  }

  public fetchAuthenticatedUser(): Promise<UserOutput> {
    return this.fetch<UserOutput, void>(this.fetchAuthenticatedUserApiQuery);
  }

  public markUserTermsOfUseAccepted(): Observable<void> {
    return this.mutate$(this._markUserTermsOfUseAcceptedApiMutation);
  }

  public inviteCoach(cmd: InviteCoachCmd): Observable<void> {
    return this.mutate$(this._inviteCoachApiMutation, {
      lastName: cmd.lastName,
      firstName: cmd.firstName,
      emailAddress: cmd.emailAddress,
      organizationName: cmd.organizationName,
    });
  }
}
