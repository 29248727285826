import { Injectable } from '@angular/core';
import { ApiMutation } from '../../../../common/api.mutation';
import gql from 'graphql-tag';

export interface CreateUserPasswordInput {
  readonly activationCode: string;
  readonly auth0UserId?: string;
  readonly password?: string;
}

export interface CreateUserPasswordParams {
  readonly input: CreateUserPasswordInput;
}

@Injectable({ providedIn: 'root' })
export class CreateUserPasswordApiMutation extends ApiMutation<
  string,
  CreateUserPasswordParams
> {
  client = 'iam';
  document = gql`
    mutation CreateUserPassword($input: CreateUserPasswordInput!) {
      createUserPassword(input: $input)
    }
  `;
  method = 'createUserPassword';
}
