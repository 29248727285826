import { Injectable } from '@angular/core';
import { ApiMutation } from '../../../../common/api.mutation';
import gql from 'graphql-tag';

@Injectable({ providedIn: 'root' })
export class MarkUserTermsOfUseAcceptedApiMutation extends ApiMutation<
  void,
  void
> {
  client = 'iam';
  document = gql`
    mutation MarkUserTermsOfUseAccepted {
      markUserTermsOfUseAccepted {
        id
        role
        status
        lastName
        firstName
        phoneNumber
        emailAddress
        termsOfUseAccepted
        metadata {
          welcomeMessage
        }
        properties {
          name
          type
          value
        }
        creationTimestamp
        organization {
          id
          name
          fees
          status
          creationTimestamp
          style {
            font
            primaryColor
            primaryTextColor
            secondaryColor
            secondaryTextColor
            negativeColor
            positiveColor
            textColor
            textSecondaryColor
          }
        }
      }
    }
  `;
  method = 'markUserTermsOfUseAccepted';
}
