import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserSessionPort } from '../../application/user/port/user-session.port';

@Injectable()
export class UserSessionAdapter implements UserSessionPort {
  private _jwt: BehaviorSubject<string>;
  private _topDomain: string;
  private _expiration: Date;
  private _domain: string;

  constructor(
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._domain = /(.*\.){0,}(.+\..*|localhost)/.exec(
      this.document.location.hostname
    )[2];
    this._topDomain = this._domain === 'localhost' ? null : `.${this._domain}`;
    this._jwt = new BehaviorSubject(this.cookieService.get('JW_TOKEN'));
    this._expiration = new Date(2147483647000);
  }

  public getUserJwt(): string {
    return this.cookieService.get('JW_TOKEN');
  }

  public observeUserJwt(): Observable<string> {
    return this._jwt.pipe(distinctUntilChanged());
  }

  public saveUserJwt(jwt: string): void {
    if (jwt) {
      this.cookieService.set(
        'JW_TOKEN',
        jwt,
        this._expiration,
        '/',
        this._topDomain,
        this._domain !== 'localhost',
        'Lax'
      );
      this._jwt.next(jwt);
    } else {
      this.removeUserJwt();
    }
  }

  public removeUserJwt(): void {
    this.cookieService.delete(
      'JW_TOKEN',
      '/',
      this._topDomain,
      this._domain !== 'localhost',
      'Lax'
    );
    this._jwt.next(null);
  }
}
