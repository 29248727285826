import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '../environments/environment';

const routes: Routes = [
  {
    path: 'create-password/:code',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/create-password/create-password.module').then(
        (m) => m.CreatePasswordModule
      ),
  },
  {
    path: 'change-password/:changePasswordCode',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'sign-up/:code',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/sign-up/sign-up.module').then(
        (m) => m.SignUpModule
      ),
  },
  {
    path: 'coach/sign-up',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/coach/sign-up/sign-up.module').then(
        (m) => m.SignUpModule
      ),
  },
  {
    path: 'coach/subscription-payment-success',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/coach/payment-success/payment-success.module').then(
        (m) => m.PaymentSuccessModule
      ),
  },
  {
    path: 'coach/subscription-payment-cancel',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/coach/payment-cancel/payment-cancel.module').then(
        (m) => m.PaymentCancelModule
      ),
  },
  {
    path: '**',
    data: {redirectConnected: environment.redirectConnected},
    loadChildren: () =>
      import('./presentation/page/sign-in/sign-in.module').then(
        (m) => m.SignInModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
