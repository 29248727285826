import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';

@NgModule({ exports: [HttpClientModule, ApolloModule] })
export class ApiGraphqlModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    apollo.createNamed('iam', {
      defaultOptions: {
        query: { fetchPolicy: 'no-cache' },
        mutate: { fetchPolicy: 'no-cache' },
      },
      link: httpLink.create({ uri: environment.iamGraphQLUri }),
      cache: new InMemoryCache(),
    });

    apollo.createNamed('messaging', {
      defaultOptions: {
        query: { fetchPolicy: 'no-cache' },
        mutate: { fetchPolicy: 'no-cache' },
      },
      link: httpLink.create({ uri: environment.messagingGraphQLUri }),
      cache: new InMemoryCache(),
    });
  }
}
