import { HttpErrorResponse } from '@angular/common/http';
import { ApolloError } from '@apollo/client';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { UserSessionPort } from '../../application/user/port/user-session.port';

import { ApiMutation } from './api.mutation';
import { ApiQuery } from './api.query';

export class ApiAdapter {
  constructor(
    protected userSessionPort: UserSessionPort,
    private translateService: TranslateService
  ) {}

  public fetch<R, V>(query: ApiQuery<R, V>, variables?: V): Promise<R> {
    return this.fetch$(query, variables).toPromise();
  }

  public fetch$<R, V>(query: ApiQuery<R, V>, variables?: V): Observable<R> {
    return this.userSessionPort.observeUserJwt().pipe(
      take(1),
      switchMap((jwt) =>
        query
          .fetch(variables, {
            context: {
              headers: jwt
                ? {
                    Authorization: jwt,
                    'Accept-language': this.translateService.currentLang,
                  }
                : { 'Accept-language': this.translateService.currentLang },
            },
          })
          .pipe(
            map((result) => result.data[query.method] as R),
            catchError((error: ApolloError) => this._handleGraphQLError(error))
          )
      )
    );
  }

  public mutate<R, V>(mutation: ApiMutation<R, V>, variables?: V): Promise<R> {
    return this.mutate$(mutation, variables).toPromise();
  }

  public mutate$<R, V>(
    mutation: ApiMutation<R, V>,
    variables?: V
  ): Observable<R> {
    return this.userSessionPort.observeUserJwt().pipe(
      take(1),
      switchMap((jwt) =>
        mutation
          .mutate(variables, {
            context: {
              headers: jwt
                ? {
                    Authorization: jwt,
                    'Accept-language': this.translateService.currentLang,
                  }
                : { 'Accept-language': this.translateService.currentLang },
            },
          })
          .pipe(
            map((result) => result.data[mutation.method] as R),
            catchError((error: ApolloError) => this._handleGraphQLError(error))
          )
      )
    );
  }

  private _handleGraphQLError(error: ApolloError): Observable<never> {
    if (error.networkError) {
      if (error.networkError instanceof HttpErrorResponse) {
        switch (error.networkError.status) {
          case 401:
            this.userSessionPort.removeUserJwt();
            return throwError(new Error('error.common.unauthorized'));
          default:
            return throwError(new Error('error.common.network-request'));
        }
      } else {
        return throwError(new Error('error.common.network-request'));
      }
    } else {
      return throwError(
        new Error(error.graphQLErrors.map((e) => e.message).join('\n'))
      );
    }
  }
}
