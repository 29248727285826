import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AppState {
  organizationId: string;
  redirecting: boolean;
  loading: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui-app' })
export class AppStore extends Store<AppState> {
  constructor() {
    super({ loading: true });
  }
}
