import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { delay, first, switchMapTo, tap } from 'rxjs/operators';
import { AppController } from './app.controller';
import { AppService } from './app.service';

@Component({
  selector: 'spr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService],
  host: {
    class: 'h-full flex flex-col',
  },
})
export class AppComponent {
  public loading$: Observable<boolean>;
  public redirecting$: Observable<boolean>;

  constructor(
    controller: AppController,
    appService: AppService,
    translateService: TranslateService,
    updates: SwUpdate,
    @Inject(DOCUMENT) private _document: Document,
    private _messageService: MessageService
  ) {
    updates.available
      .pipe(
        switchMapTo(translateService.stream('common.update')),
        first(),
        tap((message) =>
          this._messageService.add({
            severity: 'info',
            detail: message,
            sticky: true,
          })
        ),
        delay(3000),
        tap(() => this._document.location.reload())
      )
      .subscribe();
    this.redirecting$ = controller.redirecting$;
    this.loading$ = controller.loading$;
  }
}
