import { Injectable } from '@angular/core';
import { ApiMutation } from '../../../../common/api.mutation';
import gql from 'graphql-tag';

export interface ChangeUserPasswordInput {
  readonly resetCode: string;
  readonly password: string;
}

export interface ChangeUserPasswordParams {
  readonly input: ChangeUserPasswordInput;
}

@Injectable({ providedIn: 'root' })
export class ChangeUserPasswordApiMutation extends ApiMutation<
  string,
  ChangeUserPasswordParams
> {
  client = 'iam';
  document = gql`
    mutation ChangeUserPassword($input: ChangeUserPasswordInput!) {
      changeUserPassword(input: $input)
    }
  `;
  method = 'changeUserPassword';
}
