import { Injectable } from '@angular/core';
import { ApiQuery } from '../../../../common/api.query';
import gql from 'graphql-tag';

export interface GenerateUserJwtWithNetworkParams {
  readonly auth0UserId: string;
}

@Injectable({ providedIn: 'root' })
export class GenerateUserJwtWithNetworkApiQuery extends ApiQuery<
  string,
  GenerateUserJwtWithNetworkParams
> {
  client = 'iam';
  document = gql`
    query GenerateUserJwtWithNetwork($auth0UserId: String!) {
      generateUserJwtWithNetwork(auth0UserId: $auth0UserId)
    }
  `;
  method = 'generateUserJwtWithNetwork';
}
