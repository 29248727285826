import { NgModule } from '@angular/core';
import { OrganizationApplicationAdapter } from './application/organization/organization-application.adapter';
import { OrganizationApiPort } from './application/organization/port/organization-api.port';
import { OrganizationApplicationPort } from './application/organization/port/organization-application.port';
import { UserApiPort } from './application/user/port/user-api.port';
import { UserApplicationPort } from './application/user/port/user-application.port';
import { UserSessionPort } from './application/user/port/user-session.port';
import { UserApplicationAdapter } from './application/user/user-application.adapter';
import { OrganizationApiAdapter } from './infrastructure/organization/organization-api.adapter';
import { UserApiAdapter } from './infrastructure/user/user-api.adapter';
import { UserSessionAdapter } from './infrastructure/user/user-session.adapter';

@NgModule({
  providers: [
    {
      provide: OrganizationApplicationPort,
      useClass: OrganizationApplicationAdapter,
    },
    { provide: UserApplicationPort, useClass: UserApplicationAdapter },
    { provide: OrganizationApiPort, useClass: OrganizationApiAdapter },
    { provide: UserSessionPort, useClass: UserSessionAdapter },
    { provide: UserApiPort, useClass: UserApiAdapter },
  ],
})
export class AppServicesModule {}
