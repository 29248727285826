import { Injectable } from '@angular/core';
import { ApiMutation } from '../../../../common/api.mutation';
import gql from 'graphql-tag';

export interface InviteCoachParams {
  readonly emailAddress: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly organizationName: string;
}

@Injectable({ providedIn: 'root' })
export class InviteCoachApiMutation extends ApiMutation<
  void,
  InviteCoachParams
> {
  client = 'iam';
  document = gql`
    mutation InviteCoach(
      $emailAddress: String!
      $firstName: String!
      $lastName: String!
      $organizationName: String!
    ) {
      inviteCoach(
        emailAddress: $emailAddress
        firstName: $firstName
        lastName: $lastName
        organizationName: $organizationName
      )
    }
  `;
  method = 'inviteCoach';
}
