<div
  class="
    overflow-auto
    p-2
    border border-solid
    p-border-color-component-background
  "
  style="max-height: 300px"
  [innerHTML]="html$ | async"
></div>
<div class="block my-4">
  <p-checkbox
    [(ngModel)]="shareWithCoach"
    [label]="'terms-of-use-dialog.shareWithCoach' | translate"
    binary="true"
    labelStyleClass="font-bold"
    class="block"
  ></p-checkbox>
  <p-checkbox
    [(ngModel)]="acceptTracking"
    [label]="'terms-of-use-dialog.acceptTracking' | translate"
    binary="true"
    labelStyleClass="font-bold"
    class="block mt-2"
  ></p-checkbox>
  <p-checkbox
    [(ngModel)]="grantNewsLetter"
    [label]="'terms-of-use-dialog.grantNewsLetter' | translate"
    binary="true"
    class="block mt-2"
  ></p-checkbox>
</div>
<div class="flex flex-wrap mr-0 ml-0 mt-0 items-center justify-between">
  <button
    [label]="'terms-of-use-dialog.refuse' | translate"
    (click)="onCloseClick()"
    class="p-button-primary-transparent"
    pButton
  ></button>
  <button
    [label]="'terms-of-use-dialog.accept' | translate"
    [disabled]="!acceptTracking || !shareWithCoach"
    (click)="onAcceptClick()"
    pButton
  ></button>
</div>
