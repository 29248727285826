import { Injectable } from '@angular/core';
import { ApiQuery } from '../../../../common/api.query';
import gql from 'graphql-tag';

export interface GenerateUserJwtWithCredentialsInput {
  readonly emailAddress: string;
  readonly rawPassword: string;
}

export interface GenerateUserJwtWithCredentialsParams {
  readonly input: GenerateUserJwtWithCredentialsInput;
}

@Injectable({ providedIn: 'root' })
export class GenerateUserJwtWithCredentialsApiQuery extends ApiQuery<
  string,
  GenerateUserJwtWithCredentialsParams
> {
  client = 'iam';
  document = gql`
    query GenerateUserJwtWithCredentials(
      $input: GenerateUserJwtWithCredentialsInput!
    ) {
      generateUserJwtWithCredentials(input: $input)
    }
  `;
  method = 'generateUserJwtWithCredentials';
}
