import { Injectable } from '@angular/core';
import { ApiMutation } from '../../../../common/api.mutation';
import gql from 'graphql-tag';

export interface RequestResetPasswordParams {
  readonly emailAddress: string;
}

@Injectable({ providedIn: 'root' })
export class RequestResetPasswordApiMutation extends ApiMutation<
  boolean,
  RequestResetPasswordParams
> {
  client = 'iam';
  document = gql`
    mutation requestResetPassword($emailAddress: String!) {
      requestResetPassword(emailAddress: $emailAddress)
    }
  `;
  method = 'requestResetPassword';
}
