import { Injectable } from '@angular/core';
import { ApiQuery } from '../../../../common/api.query';
import { UserOutput } from '../model/user.output';
import gql from 'graphql-tag';

export interface FetchUserByActivationCodeParams {
  readonly activationCode: string;
}

@Injectable({ providedIn: 'root' })
export class FetchUserByActivationCodeApiQuery extends ApiQuery<
  UserOutput,
  FetchUserByActivationCodeParams
> {
  client = 'iam';
  document = gql`
    query FetchUserByActivationCode($activationCode: String!) {
      fetchUserByActivationCode(activationCode: $activationCode) {
        id
        role
        status
        lastName
        firstName
        phoneNumber
        emailAddress
        termsOfUseAccepted
        metadata {
          welcomeMessage
        }
        properties {
          name
          type
          value
        }
        creationTimestamp
        organization {
          id
          name
          fees
          status
          creationTimestamp
          style {
            font
            primaryColor
            primaryTextColor
            secondaryColor
            secondaryTextColor
            negativeColor
            positiveColor
            textColor
            textSecondaryColor
          }
        }
      }
    }
  `;
  method = 'fetchUserByActivationCode';
}
