import { Observable } from 'rxjs';

export abstract class UserSessionPort {
  public abstract getUserJwt(): string;

  public abstract observeUserJwt(): Observable<string>;

  public abstract saveUserJwt(jwt: string): void;

  public abstract removeUserJwt(): void;
}
