import { Injectable } from '@angular/core';
import { ApiMutation } from '../../../../common/api.mutation';
import gql from 'graphql-tag';

export interface RequestInvitationInput {
  readonly emailAddress: string;
  readonly firstName: string;
  readonly invitationCode: string;
  readonly language: string;
  readonly lastName: string;
  readonly organizationId: string;
}

export interface RequestInvitationParams {
  input: RequestInvitationInput;
}

@Injectable({ providedIn: 'root' })
export class RequestInvitationApiMutation extends ApiMutation<
  boolean,
  RequestInvitationParams
> {
  client = 'iam';
  document = gql`
    mutation requestInvitation($input: RequestInvitationInput!) {
      requestInvitation(input: $input)
    }
  `;
  method = 'requestInvitation';
}
