import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as LogRocket from 'logrocket';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import packageInfo from '../../../../package.json';
import { environment } from '../environments/environment';
import { AppPrimengModule } from './app-primeng.module';

import { AppRoutingModule } from './app-routing.module';
import { AppServicesModule } from './app-services.module';
import { ApiGraphqlModule } from './infrastructure/common/api-graphql.module';
import { AppComponent } from './presentation/app.component';

export function initApp(translate: TranslateService, document: Document) {
  return () =>
    of(document.location)
      .pipe(
        map((location) => {
          if (environment.production && location.host !== environment.domain) {
            location.href = `${location.protocol}//${environment.domain}${location.pathname}`;
            return true;
          } else {
            return false;
          }
        }),
        filter((redirect) => !redirect),
        tap(() => {
          const language = ['en', 'fr'].includes(translate.getBrowserLang())
            ? translate.getBrowserLang()
            : 'en';
          if (environment.logRocketEnabled) {
            LogRocket.init('mab-ventures/spreeng-auth', {
              release: packageInfo.version,
            });
          }
          translate.setDefaultLang('en');
          translate.use(language);
        })
      )
      .toPromise();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppPrimengModule,
    ApiGraphqlModule,
    AppServicesModule,
    BrowserAnimationsModule,
    AkitaNgRouterStoreModule,
    environment.production ? [] : [AkitaNgDevtools.forRoot()],
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    {
      deps: [TranslateService, DOCUMENT],
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
