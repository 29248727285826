import { Injectable } from '@angular/core';
import { ApiMutation } from 'apps/streeme-auth-ui/src/app/infrastructure/common/api.mutation';
import gql from 'graphql-tag';

export interface UpdateUserGrantNewsLetterParams {
  readonly grantNewsLetter: boolean;
}

@Injectable({ providedIn: 'root' })
export class UpdateUserGrantNewsLetterApiMutation extends ApiMutation<
  void,
  UpdateUserGrantNewsLetterParams
> {
  client = 'messaging';
  document = gql`
    mutation UpdateUserGrantNewsLetter($grantNewsLetter: Boolean!) {
      updateUserGrantNewsLetter(grantNewsLetter: $grantNewsLetter)
    }
  `;
  method = 'updateUserGrantNewsLetter';
}
