import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppMessageModel } from './app-message.model';

@Injectable({ providedIn: 'root' })
export class AppMessagingService {
  private _message$ = new Subject<AppMessageModel>();

  public publishMessage(message: AppMessageModel): void {
    this._message$.next(message);
  }

  public observeMessage(): Observable<AppMessageModel> {
    return this._message$;
  }
}
