import { NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  exports: [
    ButtonModule,
    InputTextModule,
    CardModule,
    DropdownModule,
    ProgressSpinnerModule,
    PasswordModule,
    ToastModule,
    CheckboxModule,
    DynamicDialogModule,
    TooltipModule,
    InputMaskModule,
  ],
  providers: [MessageService, DialogService],
})
export class AppPrimengModule {}
