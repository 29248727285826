import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserApplicationPort } from '../../../../application/user/port/user-application.port';

@Component({
  selector: 'spr-terms-of-use-dialog',
  templateUrl: './terms-of-use-dialog.component.html',
  styleUrls: ['./terms-of-use-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfUseDialogComponent {
  public html$: Observable<string>;
  public shareWithCoach: boolean;
  public acceptTracking: boolean;
  public grantNewsLetter: boolean;

  constructor(
    private _ref: DynamicDialogRef,
    private _httpClient: HttpClient,
    private _config: DynamicDialogConfig,
    private _userApplicationPort: UserApplicationPort
  ) {
    this.html$ = _httpClient.get(
      `assets/i18n/content/tou_${_config.data.language}.html`,
      { responseType: 'text' }
    );
  }

  public onCloseClick() {
    this._userApplicationPort.clearSession();
    this._ref.close();
  }

  public onAcceptClick() {
    combineLatest([
      this._userApplicationPort.markUserTermsOfUseAccepted(),
      this._userApplicationPort.updateUserGrantNewsLetter(this.grantNewsLetter),
    ])
      .pipe(
        tap(() => this._config.data.redirect()),
        tap(() => this._ref.close())
      )
      .subscribe();
  }
}
