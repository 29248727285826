import { Injectable } from '@angular/core';
import { ApiQuery } from '../../../../common/api.query';

import { OrganizationOutput } from '../model/organization.output';
import gql from 'graphql-tag';

export interface FetchOrganizationByIdParams {
  readonly id: string;
}

@Injectable({ providedIn: 'root' })
export class FetchOrganizationByIdApiQuery extends ApiQuery<
  OrganizationOutput,
  FetchOrganizationByIdParams
> {
  client = 'iam';
  document = gql`
    query FetchOrganizationById($id: UUID!) {
      fetchOrganizationById(id: $id) {
        id
        name
        fees
        status
        domains
        creationTimestamp
        style {
          font
          primaryColor
          primaryTextColor
          secondaryColor
          secondaryTextColor
          negativeColor
          positiveColor
          textColor
          textSecondaryColor
        }
        properties {
          name
          type
          value
        }
      }
    }
  `;
  method = 'fetchOrganizationById';
}
