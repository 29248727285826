export const environment = {
  defaultStyleUrl:
    'https://iam-service.streeme.io/v1/organizations/public-style/v2',
  styleUrl:
    'https://iam-service.streeme.io/v1/organizations/{id}/public-style/v2',
  messagingGraphQLUri: 'https://messaging-service.streeme.io/graphql',
  iamGraphQLUri: 'https://iam-service.streeme.io/graphql',
  auth0: {
    clientId: '9wz9cWCqS0oMigNnqXqZ0ASw3aV5e8Qf',
    domain: 'oauth.streeme.io',
  },
  publicWebsiteUrl: 'https://my.streeme.io/',
  clientWebsiteUrl: 'https://client.streeme.io/',
  pricingUrl: 'https://www.streeme.io/pricing',
  domain: 'auth.streeme.io',
  redirectConnected: true,
  logRocketEnabled: true,
  production: true,
  e2e: false,
};
