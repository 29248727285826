import { GenerateUserJwtWithMfaQuery } from 'apps/streeme-auth-ui/src/app/application/user/query/generate-user-jwt-with-mfa.query';
import { Observable } from 'rxjs';
import { ChangeUserPasswordCmd } from '../command/change-user-password.cmd';
import { CreateUserPasswordCmd } from '../command/create-user-password.cmd';
import { RequestInvitationCmd } from '../command/request-invitation.cmd';
import { GenerateUserJwtWithCredentialsQuery } from '../query/generate-user-jwt-with-credentials.query';
import { UserOutput } from '../../../infrastructure/graphql/iam/user/model/user.output';
import { InviteCoachCmd } from '../command/invite-coach.cmd';

export abstract class UserApplicationPort {
  public abstract createUserPassword(cmd: CreateUserPasswordCmd): Promise<void>;

  public abstract updateUserGrantNewsLetter(
    grantNewsLetter: boolean
  ): Observable<void>;

  public abstract changeUserPassword(cmd: ChangeUserPasswordCmd): Promise<void>;

  public abstract fetchUserByActivationCode(
    activationCode: string
  ): Promise<UserOutput>;

  public abstract fetchUserByChangePasswordCode(
    changePasswordCode: string
  ): Promise<UserOutput>;

  public abstract observeConnectedUser(): Observable<UserOutput>;

  public abstract requestResetPassword(emailAddress: string): Promise<void>;

  public abstract generateUserJwtWithMfa(
    query: GenerateUserJwtWithMfaQuery
  ): Observable<void>;

  public abstract generateUserJwtWithCredentials(
    query: GenerateUserJwtWithCredentialsQuery
  ): Promise<{ jwt: string; isAlt: boolean }>;

  public abstract generateUserJwtWithNetwork(
    auth0UserId: string
  ): Promise<string>;

  public abstract clearSession(): void;

  public abstract markUserTermsOfUseAccepted(): Observable<void>;

  public abstract requestInvitation(
    cmd: RequestInvitationCmd
  ): Observable<boolean>;

  public abstract inviteCoach(cmd: InviteCoachCmd): Observable<void>;
}
