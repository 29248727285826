import { Injectable } from '@angular/core';
import { ApiQuery } from '../../../../common/api.query';

import { OrganizationOutput } from '../model/organization.output';
import gql from 'graphql-tag';

export interface FetchOrganizationByInvitationCodeParams {
  readonly code: string;
}

@Injectable({ providedIn: 'root' })
export class FetchOrganizationByInvitationCodeApiQuery extends ApiQuery<
  OrganizationOutput,
  FetchOrganizationByInvitationCodeParams
> {
  client = 'iam';
  document = gql`
    query FetchOrganizationByInvitationCode($code: String!) {
      fetchOrganizationByInvitationCode(code: $code) {
        id
        name
        fees
        status
        domains
        creationTimestamp
        style {
          font
          primaryColor
          primaryTextColor
          secondaryColor
          secondaryTextColor
          negativeColor
          positiveColor
          textColor
          textSecondaryColor
        }
        properties {
          name
          type
          value
        }
      }
    }
  `;
  method = 'fetchOrganizationByInvitationCode';
}
