import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationApiPort } from './port/organization-api.port';
import { OrganizationApplicationPort } from './port/organization-application.port';
import {OrganizationOutput} from "../../infrastructure/graphql/iam/organization/model/organization.output";

@Injectable()
export class OrganizationApplicationAdapter
  implements OrganizationApplicationPort
{
  constructor(private organizationApiPort: OrganizationApiPort) {}

  public fetchOrganizationById(id: string): Observable<OrganizationOutput> {
    return this.organizationApiPort.fetchOrganizationById(id);
  }

  public fetchOrganizationByInvitationCode(
    code: string
  ): Observable<OrganizationOutput> {
    return this.organizationApiPort.fetchOrganizationByInvitationCode(code);
  }
}
