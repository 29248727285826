import { Observable } from 'rxjs';
import {OrganizationOutput} from "../../../infrastructure/graphql/iam/organization/model/organization.output";

export abstract class OrganizationApiPort {
  public abstract fetchOrganizationById(
    id: string
  ): Observable<OrganizationOutput>;

  public abstract fetchOrganizationByInvitationCode(
    code: string
  ): Observable<OrganizationOutput>;
}
