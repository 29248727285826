import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { ComponentController } from '@lycoris-nubila/ngx-lycoris';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UserPropertyName } from '../infrastructure/common/api.types';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, EMPTY, forkJoin } from 'rxjs';
import { filter, map, switchMapTo, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppMessagingService } from '../application/common/app-messaging.service';
import { UserRole } from '../application/user/model/user-role';
import { UserApplicationPort } from '../application/user/port/user-application.port';
import { TermsOfUseDialogComponent } from './common/dialog/terms-of-use-dialog/terms-of-use-dialog.component';
import { AppState, AppStore } from './app.store';
import { UserOutput } from '../infrastructure/graphql/iam/user/model/user.output';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class AppService extends ComponentController<AppState> {
  constructor(
    store: AppStore,
    private _routerQuery: RouterQuery,
    private _dialogService: DialogService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private _messagingService: AppMessagingService,
    private _userApplicationPort: UserApplicationPort
  ) {
    super(store);

    this._initListeners();
  }

  public updateOrganizationId(organizationId: string): void {
    this.store.update({ organizationId });
  }

  private _redirectUser(user: UserOutput, redirectConnected: boolean): void {
    if (!redirectConnected) {
      this.store.update({ redirecting: false, loading: false });
      return;
    }

    this.store.update({ redirecting: true, loading: true });

    switch (user.role) {
      case UserRole.ADMIN:
      case UserRole.MANAGER:
        document.location.href = environment.clientWebsiteUrl;
        break;
      case UserRole.COACH:
        this.isUserPropertyTrue(user, 'OLD_UI_ENABLED', false)
          ? (document.location.href = environment.publicWebsiteUrl)
          : (document.location.href = environment.clientWebsiteUrl);
        break;
      default:
        document.location.href = environment.publicWebsiteUrl;
        break;
    }
  }

  private isUserPropertyTrue(
    user: UserOutput,
    name: UserPropertyName,
    defaultValue: boolean
  ): boolean {
    const propertyValue = (user.properties || []).find(
      (property) => property.name === name
    )?.value;

    return propertyValue ? propertyValue === 'true' : defaultValue;
  }

  private _initListeners() {
    combineLatest([
      this._userApplicationPort.observeConnectedUser(),
      this._routerQuery.selectData(),
    ])
      .pipe(
        map(([user, data]) => this._redirectIfNeeded(user, data)),
        untilDestroyed(this)
      )
      .subscribe();

    this._messagingService
      .observeMessage()
      .pipe(
        filter((message) => !!message.summaryKey && !!message.detailKey),
        tap((message) =>
          this._showMessage(
            message.severity,
            message.summaryKey,
            message.detailKey,
            message.params
          )
        ),
        untilDestroyed(this)
      )
      .subscribe();

    this.select('organizationId')
      .pipe(
        tap(
          (organizationId) =>
            ((document.getElementById('theme') as HTMLLinkElement).href =
              organizationId && !environment.e2e
                ? environment.styleUrl.replace('{id}', organizationId)
                : environment.defaultStyleUrl)
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private _showMessage(
    severity: 'success' | 'error' | 'info',
    summaryKey: string,
    detailKey: string,
    params: any
  ): Promise<void> {
    return forkJoin([
      this._translateService.get(detailKey, params),
      this._translateService.get(summaryKey, params),
    ])
      .pipe(
        tap(([detail, summary]) =>
          this._messageService.add({ detail, severity, summary, life: 5000 })
        ),
        switchMapTo(EMPTY)
      )
      .toPromise();
  }

  private _redirectIfNeeded(
    user: UserOutput,
    data: { [key: string]: boolean }
  ): void {
    if (!user) {
      this.store.update({ redirecting: false, loading: false });
      return;
    }

    if (!user.termsOfUseAccepted) {
      this._dialogService.open(TermsOfUseDialogComponent, {
        header: this._translateService.instant('terms-of-use-dialog.header'),
        data: {
          language: this._translateService.currentLang,
          redirect: () => this._redirectUser(user, data.redirectConnected),
        },
        dismissableMask: false,
        closeOnEscape: false,
        closable: false,
        width: '600px',
        modal: true,
      });
      this.store.update({ redirecting: false, loading: false });
      return;
    }

    this._redirectUser(user, data.redirectConnected);
  }
}
